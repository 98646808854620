export default {
  state: {
    restaurants: [],
    connectionStatus: false
  },
  getters: {
    restaurants(state) {
      return state.restaurants
    },
    connectionStatus: state => state.connectionStatus
  },
  mutations: {
    set_restaurants(state, data) {
      state.restaurants = data
    },
    set_restaurant_status(state, is_active) {
      state.connectionStatus = is_active
    }
  },
  actions: {
    async load_restaurants(ctx) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/restaurant/all'
      })
      // console.log('hey', response)
      ctx.commit('set_restaurants', response)
    },
    async checkRestaurantConnection(ctx, data) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/restaurant/ping',
        body: data,
      })
      let r = await response
      if (r != true) {
        if (r.error_class == 'InvalidRestTokenException') {
          ctx.commit('set_restaurant_status', 'InvalidRestTokenException')
        }
        else {
          ctx.commit('set_restaurant_status', false)
        }
      } else {
        ctx.commit('set_restaurant_status', true)
      }
    },
    async updateRestaurant(ctx, data) {
      // console.log(data)
      let response = await ctx.dispatch('api_request', {
        endpoint: '/restaurant/update',
        body: data,
      })
      // console.log('update: ', await response)
      await response
      ctx.dispatch('load_restaurants')
    },

    async deleteRestaurant(ctx, data) {
      let response = await ctx.dispatch('api_request', {
        endpoint: '/restaurant/delete',
        body: data
      })
      // console.log('delete: ', await response)
      await response
      ctx.dispatch('load_restaurants')
    }
  },
}