export default {
  actions: {
    async login({ dispatch }, { login, password }) {
      let response = await dispatch('api_request', {
        endpoint: '/login',
        body: {
          login, password
        }
      })
      // console.log(response)
      await dispatch('set_token', response)
    },
    async logout({ dispatch }) {
      await dispatch('unset_token', undefined)
    }
  }
}