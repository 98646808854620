<template>
  <div id="app">
    <v-app id="inspire">
      <v-main style="background-color: rgb(229, 241, 229)">
        <v-container fluid fill-height class="justify-center items-center">
          <!-- <v-row justify="center">
            <v-col md="4" sm="8" cols="12"> -->
          <v-card elevation="12" width="30%" min-width="300px" height="300px">
            <v-toolbar color="green darken-1" dark flat>
              <v-toolbar-title> Carbis Yandex.Eda </v-toolbar-title>
            </v-toolbar>
            <div
              v-if="loading"
              class="d-flex justify-center align-center fill-height"
            >
              <Loader v-if="loading" />
            </div>
            <v-form v-else @submit.prevent="submit">
              <v-card-text style="height: 70%">
                <v-text-field
                  label="E-mail"
                  prepend-icon="mdi-account"
                  type="text"
                  placeholder="Введите login"
                  v-model="login"
                  color="green"
                  required
                ></v-text-field>
                <v-text-field
                  label="Пароль"
                  prepend-icon="mdi-lock"
                  type="password"
                  placeholder="Введите пароль"
                  v-model="password"
                  color="green"
                  required
                ></v-text-field>
                <v-snackbar v-model="error" color="red">
                  <v-icon>mdi-alert-circle-outline</v-icon>
                  {{ error_text }}
                </v-snackbar>
              </v-card-text>
              <v-card-actions class="pa-4" v-if="!loading">
                <!-- <v-btn type="button" color="green darken-2" dark text large>
                  Забыли пароль?
                </v-btn> -->
                <v-spacer />
                <v-btn type="submit" color="green darken-2" dark text large>
                  Войти
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
          <!-- </v-col>
          
          </v-row> -->
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Loader from "../../components/ui/Loader.vue";

export default {
  name: "Login",
  data: () => ({
    login: "",
    password: "",
    loading: false,
    error: false,
    error_text: "",
  }),
  components: {
    Loader,
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$store.dispatch("login", {
          login: this.login,
          password: this.password,
        });
      } catch (e) {
        this.error = true;
        this.error_text = e.message;
        this.password = "";
      }
      this.loading = false;
    },
  },
};
</script>