
export default {
  state: {
    token: undefined,
  },
  getters: {
    token: state => state.token
  },
  mutations: {
    set_state_token(state, token) {
      state.token = token
      if (token == undefined) {
        localStorage.removeItem('AuthToken')
      } else {
        localStorage.setItem('AuthToken', token)
      }
    }
  },
  actions: {
    set_token(ctx, user) {
      // console.log(user)
      let token = user.access_token
      ctx.commit('set_state_token', token)
    },
    unset_token(ctx) {
      ctx.commit('set_state_token', undefined)
    },
    find_token(ctx) {
      // Загружаем токен из локального хранилища
      let token = localStorage.getItem('AuthToken')
      // console.log(token)
      if (token != undefined) {
        ctx.commit('set_state_token', token)
        let response = ctx.dispatch('api_request', {
          endpoint: '/check_token',
          body: {}
        })
        if (!response)
          token = undefined
        ctx.commit('set_state_token', token)
      }
    },

  }
}