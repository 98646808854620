import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import token from './modules/token'
import api from './modules/api'
import restaurants from './modules/restaurants'
import authorization from './modules/authorization'
import restaurantDialog from './modules/restaurantDialog'
import infoDialog from './modules/infoDialog'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    token,
    api,
    restaurants,
    authorization,
    restaurantDialog,
    infoDialog
  }
})
