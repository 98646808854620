export default {
    state: {
        showInfoDialog: false,
        connectionDict: {
            true: 'Подключение успешно!',
            false: 'Покдлючение не установлено!',
            InvalidRestTokenException: 'Неверный токен!',
        },
        messageText: ''
    },

    getters: {
        showInfoDialog: state => state.showInfoDialog,
        connectionDict: state => state.connectionDict,
        messageText: state => state.messageText
    },

    actions: {
        openInfoDialog({ commit, getters }, currentConnection) {
            commit('showInfoDialog', getters.connectionDict[currentConnection])
        },

    },

    mutations: {
        showInfoDialog(state, text) {
            state.showInfoDialog = !state.showInfoDialog
            state.messageText = text
        }
    }
}