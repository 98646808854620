export default {
    state: {
        currentRestaurant: {},
        showRestaurantDialog: false,
    },

    getters: {
        currentRestaurant: state => state.currentRestaurant,
        showRestaurantDialog: state => state.showRestaurantDialog
    },

    actions: {
        openRestaurantDialog({ commit }, restaurant) {
            commit('showDialog', restaurant)
        }
    },

    mutations: {
        showDialog(state, restaurant) {
            state.showRestaurantDialog = !state.showRestaurantDialog
            state.currentRestaurant = restaurant
        }
    }
}