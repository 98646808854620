<template>
  <div id="app">
    <component :is="layout" />
  </div>
  <!-- <v-app>
    <v-main style="background-color: rgb(229, 241, 229);">
      <component :is="layout" />
    </v-main>
  </v-app> -->
</template> 

<script>
import Admin from "./views/layouts/Admin";
import Login from "./views/layouts/Login";
import UserLoading from "./views/layouts/UserLoading";

import { mapActions, mapGetters } from "vuex";

import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/roboto';

export default {
  name: "App",
  components: {
    Admin,
    Login,
    UserLoading,
  },
  data: () => ({
    //
    user_loading: true,
  }),
  computed: {
    ...mapGetters(["token", "user"]),

    layout() {
      // if (this.user_loading) {
      //   return "UserLoading";
      if (this.token == undefined) {
        return "Login";
      } else {
        return "Admin";
      }
    },
  },
  async mounted() {
    await this.find_token();
    this.user_loading = false;
  },
  methods: {
    ...mapActions(["find_token"]),
  },
};
</script>

<style>

body {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
}

</style>
