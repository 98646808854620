<template>
  <v-app id="inspire">
    <v-app-bar app clipped-right color="green" dark>
      <v-toolbar-title
        >Управление ресторанами интеграции Yandex.Eda</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-icon class="mr-2">mdi-account</v-icon>
      <span class="mr-2">Администратор</span>
      <v-btn text link @click="logout">
        <v-icon class="mr-2">mdi-door</v-icon>
        Выйти
      </v-btn>
      <!-- <span>{{ user.is_admin ? 'Администратор' : 'Пользователь' }}</span> -->

      <v-progress-linear absolute bottom color="green darken-3" />
    </v-app-bar>

    <v-main style="position: relative">
      <div class="d-flex flex-column fill-height">
        <div
          class="mx-4 py-4"
          style="background: #fff; position: sticky; top: 60px; z-index: 4"
        ></div>
        <div class="pa-2 flex-grow-1">
          <router-view />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Admin",
  data: () => ({}),
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
    },
  },
  computed: {},
};
</script>