export default {
  actions: {
    async api_request({ getters, dispatch }, { endpoint, headers, body }) {

      let options = {
        headers: headers || {}
      }
      // console.log(getters.token)
      if (getters.token != undefined) {
        options.headers.token = getters.token
      }


      if (body) {
        options.method = 'POST'
        options.headers['Content-type'] = 'application/json'
        options.body = JSON.stringify(body)
      }
      let response;
      try {
        response = await fetch(getters.url + '/api/int' + endpoint, options)
      } catch (e) {
        console.log(e)
        await dispatch('unset_token')
        throw new Error('Нет соединения с сервером')
      }

      response = await response.json()

      if (!response.result) {
        console.error(`Request ${endpoint} failed: (${response.error_class}) ${response.error_text}`)

        if (response.error_class == 'UserNotFoundException' || response.error_class == 'TokenNotFoundException' || response.error_class == 'InvalidTokenException') {
          await dispatch('unset_token')
          throw new Error(response.error_text)
        }
        return response

      } else {
        return response.data
      }
    },
  },
  getters: {
    url() {
      return process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:10101' : ''
    }
  }
}